import React from "react";
import {useIntl} from "react-intl";
import messages from "lang/messages";

function Datenschutz(props) {
    const intl = useIntl();

    return (
        <div className="page page-ausstellung">
            <div className="container">
                <h1>
                    {intl.formatMessage(messages.datenschutz.h1)}
                </h1>

                {intl.formatMessage(messages.datenschutz.copy, {
                    p: (...chunks) => <p>{chunks}</p>,
                    h2: (...chunks) => <h2>{chunks}</h2>,
                    strong: (...chunks) => <strong>{chunks}</strong>,
                    br: <br/>,
                    mailto: (...chunks) => <a href="mailto:info@studiozx.de" target="_blank">{chunks}</a>,
                    mailto2: (...chunks) => <a href="mailto:datenschutz@studiozx.de" target="_blank">{chunks}</a>,
                    a1: (...chunks) => <a href="https://studiozx.de/datenschutz/" target="_blank">{chunks}</a>,
                    a2: (...chunks) => <a href="https://studiozx.de/" target="_blank">{chunks}</a>,
                    a3: (...chunks) => <a href="https://www.datenschutzkanzlei.de/" target="_blank">{chunks}</a>,
                })}
            </div>
        </div>
    )
}

export default Datenschutz;