import React, {useEffect, useRef, useState} from "react";
import messages from "lang/messages";
import {useIntl} from "react-intl";
import wrong from "img/wrong.png";
import correct from "img/correct.png";
import {gsap} from "gsap";

function Question(props) {
    const [answer, setAnswer] = useState(null);
    const intl = useIntl();
    const inner = useRef(null);

    useEffect(() => {
        if (answer) {
            props.onUpdate(props.id, answer);
        }
    }, [answer]);

    useEffect(() => {
        setAnswer(props.value);
    }, [props.value]);

    useEffect(() => {
        if(props.result) {
            gsap.to(inner.current, {width: (props.result['q' + props.id].yes/props.result.total * 100) + "%"});
        }
    }, [props.result]);

    return (
        <>
            <div className="question-container">
                <div className="question">
                    {props.data[intl.locale]}
                </div>
                {!props.result &&
                    <div className="buttons">
                        <div className={"button" + (answer === 'yes' ? " active" : "") + (answer === 'no' ? " inactive" : "")} onClick={() => setAnswer('yes')}>
                            {intl.formatMessage(messages.test.yes)}
                        </div>
                        <div className={"button" + (answer === 'no' ? " active" : "") + (answer === 'yes' ? " inactive" : "")} onClick={() => setAnswer('no')}>
                            {intl.formatMessage(messages.test.no)}
                        </div>
                    </div>
                }
                {props.result &&
                    <>
                        <div className="buttons">
                            <div className={"button disabled" + (answer === 'yes' ? " active" : "") + (answer === 'no' ? " inactive" : "")}>
                                {intl.formatMessage(messages.test.yes)}
                            </div>
                            <div className={"button disabled" + (answer === 'no' ? " active" : "") + (answer === 'yes' ? " inactive" : "")}>
                                {intl.formatMessage(messages.test.no)}
                            </div>
                        </div>
                        <div className="result">
                            <div className="is-correct">
                                <img src={props.result['q' + props.id].isCorrect ? correct : wrong} alt=""/>
                                <div className={"is-correct-label" + (props.result['q' + props.id].isCorrect ? " correct" : " wrong")}>
                                    {props.result['q' + props.id].isCorrect ? intl.formatMessage(messages.test.correct) : intl.formatMessage(messages.test.wrong)}
                                </div>
                            </div>
                            <div className="others-label">
                                {intl.formatMessage(messages.test.others)}
                            </div>
                            <div className="others-result">
                                <div className="bar">
                                    <div className="inner" ref={inner}></div>
                                    <div className="yes">{Math.round(props.result['q' + props.id].yes/props.result.total * 100)}%</div>
                                    <div className="no">{Math.round(props.result['q' + props.id].no/props.result.total * 100)}%</div>
                                </div>
                                <div className="legend">
                                    <div className="yes">{intl.formatMessage(messages.test.yes)}</div>
                                    <div className="no">{intl.formatMessage(messages.test.no)}</div>
                                </div>
                            </div>
                        </div>
                    </>
                }
            </div>
        </>
    )
}

export default Question;