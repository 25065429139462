import React from "react";
import {useIntl} from "react-intl";
import messages from "lang/messages";
import Gallery from "components/Gallery";
import locationIcon from "img/location-icon.png";

function Generali(props) {
    const intl = useIntl();

    return (
        <div className="page page-ausstellung">
            <div className="container">
                <h1>
                    {intl.formatMessage(messages.ausstellung.h1)}
                </h1>
                <p>
                    {intl.formatMessage(messages.ausstellung.p_1, {
                        strong: (...chunks) => <strong>{chunks}</strong>,
                        br: <br/>
                    })}
                </p>
                <a href="https://www.google.com/maps/place/Neumarkt,+50667+K%C3%B6ln/@50.9359639,6.9465266,18z/data=!3m1!4b1!4m6!3m5!1s0x47bf25abda0c767d:0x82861bef14a7d772!8m2!3d50.9359904!4d6.9474874!16s%2Fg%2F120qdgyw" target="_blank" class="location-link"><img src={locationIcon} alt=""/>Neumarkt</a>
            </div>
            <Gallery/>
            <div className="container">
                <h5>
                    {intl.formatMessage(messages.ausstellung.h5)}
                </h5>
            </div>
        </div>
    )
}

export default Generali;