import React from "react";
import NaviItem from "./NaviItem";
import {useIntl} from "react-intl";
import messages from "lang/messages";
import logo from "img/generali-logo-weiss.png";
import studiozx from "img/rz_logo_studio_zx_white_subline.png";

function Footer(props) {
    const intl = useIntl();

    return (
        <div className="footer">
            <div className="inner">
                <a href="https://www.generali.de/" target="_blank"><img src={logo} className="logo"/></a>
                <div className="cooperation">{intl.formatMessage(messages.footer.cooperation)}</div>
                <a href="https://studiozx.de/" target="_blank"><img src={studiozx} className="studiozx"/></a>

                <NaviItem href="/datenschutz" label={intl.formatMessage(messages.footer.datenschutz)}/>
                <NaviItem href="/impressum" className="last" label={intl.formatMessage(messages.footer.impressum)}/>
            </div>
        </div>
    )
}

export default Footer;