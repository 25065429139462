import React, {useReducer, useState} from "react";
import {useIntl} from "react-intl";
import messages from "lang/messages";
import {useGoogleReCaptcha} from "react-google-recaptcha-v3";
import * as EmailValidator from "email-validator";
import {gsap} from "gsap";
import axios from "axios";
import {Link, useParams} from "react-router-dom";

const initialState = {
    firstname: "",
    lastname: "",
    email: "",
    phone: "",
    textarea: "",
};

function reducer(state, newState) {
    return {...state, ...newState};
}

function Problem(props) {
    const [state, setState] = useReducer(reducer, initialState);
    const [load, setLoad] = useState(false);
    const [submitted, setSubmitted] = useState(false);

    const intl = useIntl();
    let {id} = useParams();
    const {executeRecaptcha} = useGoogleReCaptcha();

    let endpoint = process.env.REACT_APP_API_ENDPOINT;

    const handleReCaptchaVerify = async () => {
        const token = await executeRecaptcha('mitmachen');
        submit(token);
    };

    const handleChange = event => {
        setState({error: null});
        setState({[event.target.name]: event.target.value});
    };

    const validateForm = () => {
        let isValid = true;
        if (state.firstname.length < 1) {
            isValid = false;
            setState({error: 'firstname'});
        } else if (state.lastname.length < 1) {
            isValid = false;
            setState({error: 'lastname'});
        } else if (!EmailValidator.validate(state.email)) {
            isValid = false;
            setState({error: 'email'});
        } else if (state.textarea.length < 1) {
            isValid = false;
            setState({error: 'textarea'});
        }

        if (!isValid) {
            gsap.to('html, body', {scrollTop: 0});
        }

        return isValid;
    };

    const submit = async (token) => {
        if (!load) {
            if (validateForm()) {
                setLoad(true);

                const formData = new FormData();

                formData.append("firstname", state.firstname);
                formData.append("lastname", state.lastname);
                formData.append("email", state.email);
                formData.append("phone", state.phone);
                formData.append("textarea", state.textarea);
                formData.append("token", token);

                let config = {
                    withCredentials: true,
                };

                axios
                    .post(endpoint + '/problem/' + id, formData, config)
                    .then(response => {
                        setLoad(false);
                        setSubmitted(true);
                    })
            }
        }
    };

    return (
        <div className="page page-problem">
            {!submitted &&
                <div className="container">
                    <h1>
                        {intl.formatMessage(messages.problem.h1)}
                    </h1>
                    <p>
                        {intl.formatMessage(messages.problem.p)}
                    </p>

                    <form>
                        <div className="fields">
                            <div className="field">
                                <input type="text" name="firstname" placeholder={intl.formatMessage(messages.mitmachen.firstname) + "*"} onChange={handleChange}/>
                                {state.error === 'firstname' &&
                                    <p className="warning">{intl.formatMessage(messages.mitmachen.firstnameError)}</p>
                                }
                            </div>
                            <div className="field">
                                <input type="text" name="lastname" placeholder={intl.formatMessage(messages.mitmachen.lastname) + "*"} onChange={handleChange}/>
                                {state.error === 'lastname' &&
                                    <p className="warning">{intl.formatMessage(messages.mitmachen.lastnameError)}</p>
                                }
                            </div>
                            <div className="field">
                                <input type="text" name="email" placeholder={intl.formatMessage(messages.mitmachen.email) + "*"} onChange={handleChange}/>
                                {state.error === 'email' &&
                                    <p className="warning">{intl.formatMessage(messages.mitmachen.emailError)}</p>
                                }
                            </div>
                            <div className="field">
                                <input type="text" name="phone" placeholder={intl.formatMessage(messages.problem.phone)} onChange={handleChange}/>
                                {state.error === 'phone' &&
                                    <p className="warning">{intl.formatMessage(messages.problem.phoneError)}</p>
                                }
                            </div>
                        </div>

                        <textarea name="textarea" placeholder={intl.formatMessage(messages.problem.textarea) + "*"} onChange={handleChange}/>
                        {state.error === 'textarea' &&
                            <p className="warning">{intl.formatMessage(messages.problem.textareaError)}</p>
                        }

                        <div className="id">{intl.formatMessage(messages.problem.id)} {id}</div>

                        <div className="ds" onClick={() => state.ds ? setState({ds: false}) : setState({ds: true})}>
                            <div className="checkbox">
                                {state.ds &&
                                    <div className="inner"></div>
                                }
                            </div>
                            <div>{intl.formatMessage(messages.mitmachen.privacy, {
                                a: (...chunks) => <a href="/datenschutz" target="_blank">{chunks}</a>,
                            })}</div>
                        </div>
                        {state.ds &&
                            <>
                                <div className="recaptcha-label">
                                    {intl.formatMessage(messages.mitmachen.recaptcha, {
                                        a1: (...chunks) => <a href="https://policies.google.com/privacy" target="_blank">{chunks}</a>,
                                        a2: (...chunks) => <a href="https://policies.google.com/terms" target="_blank">{chunks}</a>,
                                    })}
                                </div>
                                <div className="submit" onClick={handleReCaptchaVerify}>{intl.formatMessage(messages.mitmachen.send)}</div>
                            </>
                        }
                    </form>
                </div>
            }
            {submitted &&
                <div className="container">
                    <h1>
                        {intl.formatMessage(messages.problem.h1)}
                    </h1>
                    <p>
                        {intl.formatMessage(messages.problem.sent)}
                    </p>

                    <Link to="/bias-o-mat/test" className="test">{intl.formatMessage(messages.mitmachen.test)}</Link>
                </div>
            }
        </div>
    )
}

export default Problem;