import React from "react";
import {useIntl} from "react-intl";
import image01 from "img/website-bild-01.jpg";
import image02 from "img/website-bild-02.jpg";
import image03 from "img/website-bild-03.jpg";
import image04 from "img/website-bild-04.jpg";
import image05 from "img/website-bild-05.jpg";
import image06 from "img/website-bild-06.jpg";
import image07 from "img/website-bild-07.jpg";
import image08 from "img/website-bild-08.jpg";
import image09 from "img/website-bild-09.jpg";
import image10 from "img/website-bild-10.jpg";
import image11 from "img/website-bild-11.jpg";
import image12 from "img/website-bild-12.jpg";
import image15 from "img/website-bild-15.jpg";
import image16 from "img/website-bild-16.jpg";
import image17 from "img/website-bild-17.jpg";
import image18 from "img/website-bild-18.jpg";
import image19 from "img/website-bild-19.jpg";
import image20 from "img/website-bild-20.jpg";
import Intro from "components/Intro";
import messages from "lang/messages";
import {Link} from "react-router-dom";

function Home(props) {
    const intl = useIntl();


    const onClose = () => {
        props.onIntro();
    }

    return (
        <>
            {props.showIntro &&
                <Intro onClose={onClose}/>
            }
            <div className="page-home">
                <img src={image01} alt=""/>
                <img src={image02} alt=""/>
                <img src={image03} alt=""/>
                <img src={image04} alt=""/>
                <img src={image05} alt=""/>
                <img src={image06} alt=""/>
                <img src={image07} alt=""/>
                <Link to="bias-o-mat" className="entry">
                    {intl.formatMessage(messages.home.cta, {
                        span: (...chunks) => <span>{chunks}</span>,
                    })}
                    <div className="triangle"></div>
                </Link>
                <img src={image08} alt=""/>
                <img src={image09} alt=""/>
                <img src={image10} alt=""/>
                <img src={image11} alt=""/>
                <img src={image12} alt=""/>

                {/*<img src={image13} alt=""/>
            <img src={image14} alt=""/>*/}

                <img src={image15} alt=""/>
                <img src={image16} alt=""/>
                <img src={image17} alt=""/>
                <img src={image18} alt=""/>
                <img src={image19} alt=""/>
                <img src={image20} alt=""/>

                {/*<div className="stoerer-wrapper">
                    <div className="stoerer">
                        {intl.formatMessage(messages.home.stoerer, {
                            span: (...chunks) => <span>{chunks}</span>,
                            strong: (...chunks) => <strong>{chunks}</strong>,
                            a1: (...chunks) => <a href="https://www.cineplex.de/film/look-at-me-deutschland/391655/ov/koeln/" target="_blank">{chunks}</a>,
                        })}
                    </div>
                </div>*/}

            </div>
        </>
    )
}

export default Home;