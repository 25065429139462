import React, {useEffect, useReducer, useState} from "react";
import {useIntl} from "react-intl";
import messages from "lang/messages";
import Question from "components/Question";
import {Link, useParams} from "react-router-dom";

const initialState = {
    firstname: "",
    lastname: "",
    email: "",
};

function reducer(state, newState) {
    return {...state, ...newState};
}

function Test(props) {
    const [state, setState] = useReducer(reducer, initialState);
    const [data, setData] = useState(null);
    const [result, setResult] = useState(null);
    const [oldIds, setOldIds] = useState([]);

    const intl = useIntl();
    let {id} = useParams();

    let endpoint = process.env.REACT_APP_API_ENDPOINT;

    useEffect(() => {
        setResult(null);
        setState({a1: null, a2: null, a3: null});
        load();
    }, [id]);

    async function load() {
        let url = endpoint + "/get/" + (id ? id : "random");

        let ids = oldIds;
        if(data && data.renew) {
            ids = [data.id];
        } else if(data) {
            ids.push(data.id);
        }
        setOldIds(ids);

        let formData = new FormData();
        formData.append("oldIds", ids.join(","));

        let options = {
            credentials: 'include',
            method: "POST",
            body: formData
        };
        try {
            const response = await fetch(url, options);
            let json = await response.json();
            onData(json);
        } catch (error) {
            console.log(error);
        }
    }

    const onData = (d) => {
        setData(d);
    }

    const onUpdate = (index, answer) => {
        setState({['a' + index]: answer});
    }

    const checkActive = () => {
        if (state.a1 && state.a2 && state.a3) {
            return " active";
        }

        return "";
    }

    const submit = async () => {
        let url = endpoint + "/submit/" + data.id;

        let formData = new FormData();
        formData.append("a1", state.a1);
        formData.append("a2", state.a2);
        formData.append("a3", state.a3);

        let options = {
            credentials: 'include',
            method: "POST",
            body: formData
        };
        try {
            const response = await fetch(url, options);
            let json = await response.json();
            onSubmit(json);
        } catch (error) {
            console.log(error);
        }
    }

    const onSubmit = (d) => {
        setResult(d);
    }

    return (
        <div className="page page-test">
            {data &&
                <div className="container">
                    <h1>
                        {intl.formatMessage(messages.test.h1)}
                    </h1>
                    <div className="columns">
                        <div className="column-left">
                            <img src={endpoint + '/uploads/' + data.filename} alt=""/>
                        </div>
                        <div className="column-right">
                            <Question data={data.q1} id={1} onUpdate={onUpdate} value={state.a1} result={result}/>
                            <Question data={data.q2} id={2} onUpdate={onUpdate} value={state.a2} result={result}/>
                            <Question data={data.q3} id={3} onUpdate={onUpdate} value={state.a3} result={result}/>

                            {!result &&
                                <div className={"submit" + checkActive()} onClick={submit}>
                                    {intl.formatMessage(messages.test.submit)}
                                </div>
                            }

                            {id
                                ? <Link to="/bias-o-mat/test" className="next">
                                    {intl.formatMessage(messages.test.next)}
                                    <div className="triangle"></div>
                                </Link>
                                : <div className="next" onClick={() => {
                                    if (id) {
                                        id = null;
                                    } else {
                                        setResult(null);
                                        setState({a1: null, a2: null, a3: null});
                                        load();
                                    }
                                }}>
                                    {intl.formatMessage(messages.test.next)}
                                    <div className="triangle"></div>
                                </div>
                            }


                            <div className="mitmachen">
                                {intl.formatMessage(messages.test.mitmachen, {
                                    br: <br/>,
                                })}
                            </div>

                            <Link to="/bias-o-mat/mitmachen" className="cta">
                                {intl.formatMessage(messages.test.cta)}
                            </Link>

                            <Link to={"/bias-o-mat/problem/" + data.id} className="problem">
                                {intl.formatMessage(messages.test.problem)}
                            </Link>
                        </div>
                    </div>
                </div>
            }
        </div>
    )
}

export default Test;