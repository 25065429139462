import { defineMessages } from 'react-intl'

export default defineMessages({
    navi: {
        home: {
            id: 'navi.home'
        },
        project: {
            id: 'navi.project'
        },
        toscani: {
            id: 'navi.toscani'
        },
        generali: {
            id: 'navi.generali'
        },
        ausstellung: {
            id: 'navi.ausstellung'
        }
    },
    footer: {
        datenschutz: {
            id: 'footer.datenschutz'
        },
        impressum: {
            id: 'footer.impressum'
        },
        cooperation: {
            id: 'footer.cooperation'
        },
    },
    intro: {
        ot: {
            id: 'intro.ot'
        },
        title: {
            id: 'intro.title'
        },
    },
    home: {
        cta: {
            id: 'home.cta'
        },
        stoerer: {
            id: 'home.stoerer'
        },
    },
    projekt: {
        h1: {
            id: 'projekt.h1'
        },
        h2_1: {
            id: 'projekt.h2_1'
        },
        p_1: {
            id: 'projekt.p_1'
        },
        h2_2: {
            id: 'projekt.h2_2'
        },
        p_2: {
            id: 'projekt.p_2'
        },
        h5: {
            id: 'projekt.h5'
        },
        h2_3: {
            id: 'projekt.h2_3'
        },
        p_3: {
            id: 'projekt.p_3'
        },
    },
    toscani: {
        h1: {
            id: 'toscani.h1'
        },
        p_1: {
            id: 'toscani.p_1'
        },
        p_2: {
            id: 'toscani.p_2'
        },
        p_3: {
            id: 'toscani.p_3'
        },
        p_4: {
            id: 'toscani.p_4'
        },
    },
    generali: {
        h1: {
            id: 'generali.h1'
        },
        p_1: {
            id: 'generali.p_1'
        },
        p_2: {
            id: 'generali.p_2'
        },
        h2: {
            id: 'generali.h2'
        },
        p_3: {
            id: 'generali.p_3'
        },
        p_4: {
            id: 'generali.p_4'
        },
        p_5: {
            id: 'generali.p_5'
        },
        a: {
            id: 'generali.a'
        },
    },
    ausstellung: {
        h1: {
            id: 'ausstellung.h1'
        },
        p_1: {
            id: 'ausstellung.p_1'
        },
        h5: {
            id: 'ausstellung.h5'
        },
    },
    datenschutz: {
        h1: {
            id: 'datenschutz.h1'
        },
        copy: {
            id: 'datenschutz.copy'
        },
    },
    impressum: {
        h1: {
            id: 'impressum.h1'
        },
        copy: {
            id: 'impressum.copy'
        },
    },
    biasOMat: {
        h2: {
            id: 'biasOMat.h2'
        },
        h1: {
            id: 'biasOMat.h1'
        },
        p_1: {
            id: 'biasOMat.p_1'
        },
        p_2: {
            id: 'biasOMat.p_2'
        },
        btn_1: {
            id: 'biasOMat.btn_1'
        },
        btn_2: {
            id: 'biasOMat.btn_2'
        },
    },
    test: {
        h1: {
            id: 'test.h1'
        },
        yes: {
            id: 'test.yes'
        },
        no: {
            id: 'test.no'
        },
        submit: {
            id: 'test.submit'
        },
        next: {
            id: 'test.next'
        },
        mitmachen: {
            id: 'test.mitmachen'
        },
        cta: {
            id: 'test.cta'
        },
        problem: {
            id: 'test.problem'
        },
        question_1: {
            id: 'test.question_1'
        },
        question_2: {
            id: 'test.question_2'
        },
        question_3: {
            id: 'test.question_3'
        },
        correct: {
            id: 'test.correct'
        },
        wrong: {
            id: 'test.wrong'
        },
        others: {
            id: 'test.others'
        },
    },
    mitmachen: {
        h1: {
            id: 'mitmachen.h1'
        },
        dropzone: {
            id: 'mitmachen.dropzone'
        },
        image: {
            id: 'mitmachen.image'
        },
        imageTooLarge: {
            id: 'mitmachen.imageTooLarge'
        },
        firstname: {
            id: 'mitmachen.firstname'
        },
        lastname: {
            id: 'mitmachen.lastname'
        },
        email: {
            id: 'mitmachen.email'
        },
        firstnameError: {
            id: 'mitmachen.firstnameError'
        },
        lastnameError: {
            id: 'mitmachen.lastnameError'
        },
        emailError: {
            id: 'mitmachen.emailError'
        },
        notVisibleForPublic: {
            id: 'mitmachen.notVisibleForPublic'
        },
        question: {
            id: 'mitmachen.question'
        },
        choose: {
            id: 'mitmachen.choose'
        },
        answer: {
            id: 'mitmachen.answer'
        },
        privacy: {
            id: 'mitmachen.privacy'
        },
        recaptcha: {
            id: 'mitmachen.recaptcha'
        },
        send: {
            id: 'mitmachen.send'
        },
        p_1: {
            id: 'mitmachen.p_1'
        },
        p_2: {
            id: 'mitmachen.p_2'
        },
        test: {
            id: 'mitmachen.test'
        },
    },
    bestaetigen: {
        checking: {
            id: 'bestaetigen.checking'
        },
        noUser: {
            id: 'bestaetigen.noUser'
        },
        p: {
            id: 'bestaetigen.p'
        },
    },
    problem: {
        h1: {
            id: 'problem.h1'
        },
        p: {
            id: 'problem.p'
        },
        phone: {
            id: 'problem.phone'
        },
        phoneError: {
            id: 'problem.phoneError'
        },
        textareaError: {
            id: 'problem.textareaError'
        },
        textarea: {
            id: 'problem.textarea'
        },
        id: {
            id: 'problem.id'
        },
        sent: {
            id: 'problem.sent'
        },
    },
});