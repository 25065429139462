import React, {useEffect} from "react";
import {useIntl} from "react-intl";
import messages from "lang/messages";
import logo from "img/generali-logo.png";
import zx from "img/studio-zx-logo.png";
import {gsap} from "gsap";

function Intro(props) {
    const intl = useIntl();
    let tween;

    useEffect(() => {
        gsap.set('.ot, .title div, .title span, .logos', {alpha: 0});
        tween = gsap.to('.ot, .title div, .title span, .logos', {
            alpha: 1, stagger: .25, ease: 'none', duration: 2, onComplete: function () {
                gsap.delayedCall(2, close);
            }
        });
    }, []);

    const close = () => {
        tween.kill();
        gsap.to('.intro', {
            alpha: 0, ease: 'none', duration: 1, onComplete: props.onClose
        });
    }

    return (
        <div className="intro">
            <div className="close" onClick={close}>
                <svg id="Ebene_1" data-name="Ebene 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 27 27">
                    <rect x="12" y="-4" width="3" height="35.79" transform="translate(-6 14) rotate(-45)"/>
                    <rect x="-4" y="12" width="36" height="3" transform="translate(-6 14) rotate(-45)"/>
                </svg>
            </div>
            <div className="ot">Oliviero Toscani</div>
            <div className="title">
                {intl.formatMessage(messages.intro.title, {
                    span: (...chunks) => <span>{chunks}</span>,
                    div: (...chunks) => <div>{chunks}</div>,
                    br: <br/>
                })}
            </div>
            <div className="logos">
                <img className="logo" src={logo} alt=""/>
                <img className="zx" src={zx} alt=""/>
            </div>
        </div>
    )
}

export default Intro;