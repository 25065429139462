import React from "react";
import {useIntl} from "react-intl";
import image01 from "img/website-bild-01.jpg";
import image02 from "img/website-bild-02.jpg";
import image03 from "img/website-bild-03.jpg";
import image04 from "img/website-bild-04.jpg";
import image05 from "img/website-bild-05.jpg";
import image06 from "img/website-bild-06.jpg";
import image07 from "img/website-bild-07.jpg";
import image08 from "img/website-bild-08.jpg";
import image09 from "img/website-bild-09.jpg";
import image10 from "img/website-bild-10.jpg";
import messages from "lang/messages";
import {Link} from "react-router-dom";
import image11 from "img/website-bild-11.jpg";
import image12 from "img/website-bild-12.jpg";
import image13 from "img/website-bild-13.jpg";
import image14 from "img/website-bild-14.jpg";
import image15 from "img/website-bild-15.jpg";

function BiasOMat(props) {
    const intl = useIntl();

    return (
        <>
            <div className="page page-bias-o-mat">
                <div className="background-bias-o-mat">
                    <img src={image01} alt=""/>
                    <img src={image02} alt=""/>
                    <img src={image03} alt=""/>
                    <img src={image04} alt=""/>
                    <img src={image05} alt=""/>
                    <img src={image06} alt=""/>
                    <img src={image07} alt=""/>
                    <img src={image08} alt=""/>
                    <img src={image09} alt=""/>
                    <img src={image10} alt=""/>
                    <img src={image11} alt=""/>
                    <img src={image12} alt=""/>
                    <img src={image13} alt=""/>
            <img src={image14} alt=""/>
                    <img src={image15} alt=""/>
                </div>
                <div className="container">
                    <h2>
                        {intl.formatMessage(messages.biasOMat.h2, {
                            br: <br/>,
                        })}
                    </h2>
                    <h1>
                        {intl.formatMessage(messages.biasOMat.h1, {
                            br: <br/>,
                        })}
                    </h1>
                    <p>
                        {intl.formatMessage(messages.biasOMat.p_1, {
                            strong: (...chunks) => <strong>{chunks}</strong>,
                            br: <br/>
                        })}
                    </p>
                    <Link to="test">{intl.formatMessage(messages.biasOMat.btn_1)}</Link>
                    <p>
                        {intl.formatMessage(messages.biasOMat.p_2)}
                    </p>
                    <Link to="mitmachen">{intl.formatMessage(messages.biasOMat.btn_2)}</Link>
                </div>
            </div>
        </>
    )
}

export default BiasOMat;