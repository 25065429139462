import React from "react";
import {useIntl} from "react-intl";
import messages from "lang/messages";
import toscani from "img/_T1_4803_rit.jpg";

function Toscani(props) {
    const intl = useIntl();

    return (
        <div className="page page-toscani">
            <div className="column-left">
                <img src={toscani} alt=""/>
            </div>
            <div className="column-right">
                <h1>
                    {intl.formatMessage(messages.toscani.h1)}
                </h1>
                <p>
                    {intl.formatMessage(messages.toscani.p_1)}
                </p>
                <p>
                    {intl.formatMessage(messages.toscani.p_2)}
                </p>
                <p>
                    {intl.formatMessage(messages.toscani.p_3)}
                </p>
                <p>
                    {intl.formatMessage(messages.toscani.p_4)}
                </p>
            </div>
        </div>
    )
}

export default Toscani;