import React from "react";

function SelectIcon(props) {
    return (
        <div className="select-icon">
            <svg viewBox="0 0 12.11 9.24">
                <path d="M0,0h12.11L6.05,9.24L0,0z"/>
            </svg>
        </div>
    );

}

export default SelectIcon;

