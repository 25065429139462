import React from "react";
import {useIntl} from "react-intl";
import messages from "lang/messages";

function Projekt(props) {
    const intl = useIntl();

    return (
        <div className="page page-projekt">
            <div className="container">
                <h1>
                    {intl.formatMessage(messages.projekt.h1)}
                </h1>
                <h2>
                    {intl.formatMessage(messages.projekt.h2_1, {
                        br: <br/>,
                    })}
                </h2>
                <p>
                    {intl.formatMessage(messages.projekt.p_1, {
                        br: <br/>,
                    })}
                </p>
                <h2>
                    {intl.formatMessage(messages.projekt.h2_2, {
                        br: <br/>,
                    })}
                </h2>
                <p>
                    {intl.formatMessage(messages.projekt.p_2, {
                        br: <br/>,
                    })}
                </p>
            </div>
            <div className="video">
                <div className="responsive-video-16-9">
                    <iframe width="560" height="315" src="https://www.youtube.com/embed/U9G1zxyIYIk" title="YouTube video player" frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                </div>
            </div>
            <div className="container">
                <h5>
                    {intl.formatMessage(messages.projekt.h5)}
                </h5>
                <h2>
                    {intl.formatMessage(messages.projekt.h2_3, {
                        br: <br/>,
                    })}
                </h2>
                <p>
                    {intl.formatMessage(messages.projekt.p_3, {
                        br: <br/>,
                    })}
                </p>
            </div>
        </div>
    )
}

export default Projekt;