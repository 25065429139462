import React, {useEffect, useRef} from "react";
import NaviItem from "./NaviItem";
import {Link} from "react-router-dom";
import {useIntl} from "react-intl";
import messages from "lang/messages";
import {gsap} from "gsap";
import useResizeObserver from '@react-hook/resize-observer';

function Header(props) {
    const intl = useIntl();
    const menu = useRef(null);

    useEffect(() => {
        gsap.set('.menu', {
            x:-window.innerWidth
        });
    }, []);

    const close = () => {
        gsap.to('.menu', {
            x:-window.innerWidth
        });
    }

    const open = () => {
        gsap.to('.menu', {
            x: 0
        });
    }

    useResizeObserver(menu, () => gsap.set('.menu', {
        x:-window.innerWidth
    }));

    return (
        <>
            <div className="header">
                <div className="inner">
                    <Link to="/" className="navi-item home">
                        {intl.formatMessage(messages.navi.home, {
                            span: (...chunks) => <span>{chunks}</span>,
                            br: <br/>
                        })}
                    </Link>

                    <NaviItem href="/projekt" label={intl.formatMessage(messages.navi.project)}/>
                    <NaviItem href="/toscani" label={intl.formatMessage(messages.navi.toscani)}/>
                    <NaviItem href="/generali" label={intl.formatMessage(messages.navi.generali)}/>
                    <NaviItem href="/ausstellung" label={intl.formatMessage(messages.navi.ausstellung)}/>

                    <div className="lang">
                        <div className={"lang-item" + (intl.locale === 'en' ? " inactive" : "")} onClick={() => props.onChangeLanguage('en')}>EN</div>
                        <div className={"lang-item" + (intl.locale === 'de' ? " inactive" : "")} onClick={() => props.onChangeLanguage('de')}>DE</div>
                    </div>

                    <div className="burger" onClick={open}>
                        <div className="burger-line"></div>
                        <div className="burger-line"></div>
                        <div className="burger-line"></div>
                    </div>
                </div>
            </div>
            <div className="menu" ref={menu}>
                <div className="close" onClick={close}>
                    <svg id="Ebene_1" data-name="Ebene 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 27 27">
                        <rect x="12" y="-4" width="3" height="35.79" transform="translate(-6 14) rotate(-45)"/>
                        <rect x="-4" y="12" width="36" height="3" transform="translate(-6 14) rotate(-45)"/>
                    </svg>
                </div>

                <Link to="/" className="navi-item home" onClick={close}>
                    {intl.formatMessage(messages.navi.home, {
                        span: (...chunks) => <span>{chunks}</span>,
                        br: <br/>
                    })}
                </Link>

                <NaviItem href="/projekt" label={intl.formatMessage(messages.navi.project)} onClick={close}/>
                <NaviItem href="/toscani" label={intl.formatMessage(messages.navi.toscani)} onClick={close}/>
                <NaviItem href="/generali" label={intl.formatMessage(messages.navi.generali)} onClick={close}/>
                <NaviItem href="/ausstellung" label={intl.formatMessage(messages.navi.ausstellung)} onClick={close}/>

                <div className="legal">
                    <NaviItem href="/datenschutz" label={intl.formatMessage(messages.footer.datenschutz)} onClick={close}/>
                    <NaviItem href="/impressum" label={intl.formatMessage(messages.footer.impressum)} onClick={close}/>
                </div>

                <div className="lang">
                    <div className={"lang-item" + (intl.locale === 'en' ? " inactive" : "")} onClick={() => {
                        //close();
                        props.onChangeLanguage('en');
                    }}>EN</div>
                    <div className={"lang-item" + (intl.locale === 'de' ? " inactive" : "")} onClick={() => {
                        //close();
                        props.onChangeLanguage('de');
                    }}>DE</div>
                </div>
            </div>
        </>
    )
}

export default Header;