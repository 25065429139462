import React from "react";
import {useIntl} from "react-intl";
import messages from "lang/messages";

function Generali(props) {
    const intl = useIntl();

    return (
        <div className="page page-generali">
            <div className="container">
                <h1>
                    {intl.formatMessage(messages.generali.h1)}
                </h1>
                <p>
                    {intl.formatMessage(messages.generali.p_1)}
                </p>
                <p>
                    {intl.formatMessage(messages.generali.p_2)}
                </p>
                <h2>
                    {intl.formatMessage(messages.generali.h2)}
                </h2>
                <p>
                    {intl.formatMessage(messages.generali.p_3)}
                </p>
                <p>
                    {intl.formatMessage(messages.generali.p_4)}
                </p>
                <p>
                    {intl.formatMessage(messages.generali.p_5)}
                </p>
                <p>
                    <a href="https://generalimagazin-no1.generali.de/de/" target="_blank">{intl.formatMessage(messages.generali.a)}</a>
                </p>
            </div>
        </div>
    )
}

export default Generali;