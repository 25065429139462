import React from "react";
import {useIntl} from "react-intl";
import messages from "lang/messages";

function Impressum(props) {
    const intl = useIntl();

    return (
        <div className="page page-ausstellung">
            <div className="container">
                <h1>
                    {intl.formatMessage(messages.impressum.h1)}
                </h1>

                {intl.formatMessage(messages.impressum.copy, {
                    p: (...chunks) => <p>{chunks}</p>,
                    h2: (...chunks) => <h2>{chunks}</h2>,
                    strong: (...chunks) => <strong>{chunks}</strong>,
                    br: <br/>
                })}
            </div>
        </div>
    )
}

export default Impressum;