import React, {useEffect, useState} from "react";
import {useIntl} from "react-intl";
import messages from "lang/messages";
import {Link, useParams} from "react-router-dom";

function Bestaetigen(props) {
    const [status, setStatus] = useState("");
    const { token } = useParams()

    const intl = useIntl();

    useEffect(() => {
        async function load() {
            let endpoint = process.env.REACT_APP_API_ENDPOINT;
            endpoint += "/bestaetigen/" + token;

            let options = {
                credentials: 'include'
            };
            try {
                const response = await fetch(endpoint, options);
                let json = await response.json();
                onStatus(json);
            } catch (error) {
                console.log(error);
            }
        }

        load();
    }, []);

    const onStatus = (data) => {
        setStatus(data.status);
    }

    return (
        <div className="page page-mitmachen">
            <div className="container">
                <h1>
                    {intl.formatMessage(messages.test.h1)}
                </h1>
                {status === "" &&
                    <p>{intl.formatMessage(messages.bestaetigen.checking)}</p>
                }
                {status === "no unapproved" &&
                    <p>{intl.formatMessage(messages.bestaetigen.noUser)}</p>
                }
                {status === "ok" &&
                    <p>{intl.formatMessage(messages.bestaetigen.p, {
                        br: <br/>,
                    })}</p>
                }

                <Link to="/bias-o-mat/test" className="test">{intl.formatMessage(messages.mitmachen.test)}</Link>
            </div>
        </div>
    )
}

export default Bestaetigen;