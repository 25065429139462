import React, {useEffect, useState} from 'react';
import './css/app.css';
import {Route, Routes} from "react-router-dom";
import Home from "./pages/Home";
import Projekt from "./pages/Projekt";
import Header from "./components/Header";
import {IntlProvider} from "react-intl";
import de from './lang/de.json';
import en from './lang/en.json';
import Footer from "components/Footer";
import Toscani from "pages/Toscani";
import Generali from "pages/Generali";
import Ausstellung from "pages/Ausstellung";
import Datenschutz from "pages/Datenschutz";
import Impressum from "pages/Impressum";
import BiasOMat from "pages/BiasOMat";
import Test from "pages/Test";
import Mitmachen from "pages/Mitmachen";
import Bestaetigen from "pages/Bestaetigen";
import Problem from "pages/Problem";

const messages = {
    'de': de,
    'en': en
};

function App() {
    const [webfont, setWebfont] = useState(false);
    const [firstRender, setFirstRender] = useState(true);
    const [language, setLanguage] = useState(navigator.language.split(/[-_]/)[0]);

    useEffect(() => {
        import('webfontloader').then(WebFont =>
            WebFont.load({

                custom: {
                    families: ['EuropaGroNr2SH-Ita', 'EuropaGroNr2SH-Rom', 'EuropaGroNr2SH-Ult', 'EuropaGroNr2SH-Bol']
                },
                active: function () {
                    setWebfont(true);
                }
            })
        )
    }, []);

    const onChangeLanguage = (lang) => {
        setLanguage(lang);
    }

    const onIntro = () => {
        setFirstRender(false);
    }

    return (
        <>
            {webfont &&
                <IntlProvider locale={language} messages={messages[language]}>
                    <Header onChangeLanguage={onChangeLanguage}/>
                    <Routes>
                        <Route path="/" element={<Home showIntro={firstRender} onIntro={onIntro}/>}/>
                        <Route path="projekt" exact element={<Projekt/>}/>
                        <Route path="toscani" exact element={<Toscani/>}/>
                        <Route path="generali" exact element={<Generali/>}/>
                        <Route path="ausstellung" exact element={<Ausstellung/>}/>
                        <Route path="datenschutz" exact element={<Datenschutz/>}/>
                        <Route path="impressum" exact element={<Impressum/>}/>

                        <Route path="bias-o-mat" exact element={<BiasOMat/>}/>
                        <Route path="bias-o-mat/test" exact element={<Test/>}/>
                        <Route path="bias-o-mat/test/:id" exact element={<Test/>}/>
                        <Route path="bias-o-mat/mitmachen" exact element={<Mitmachen/>}/>
                        <Route path="bias-o-mat/problem/:id" exact element={<Problem/>}/>

                        <Route path="bestaetigen/:token" exact element={<Bestaetigen/>}/>
                    </Routes>
                    <Footer/>
                </IntlProvider>
            }
        </>
    );
}

export default App;
