import React, {useEffect, useReducer, useRef, useState} from "react";
import {useIntl} from "react-intl";
import messages from "lang/messages";
import {useDropzone} from "react-dropzone";
import {gsap} from "gsap";
import axios from "axios";
import {NativeSelect} from "@material-ui/core";
import SelectIcon from "components/SelectIcon";
import {useGoogleReCaptcha} from "react-google-recaptcha-v3";
import * as EmailValidator from "email-validator";
import {Link} from "react-router-dom";

const initialState = {
    firstname: "",
    lastname: "",
    email: "",
};

function reducer(state, newState) {
    return {...state, ...newState};
}

function Mitmachen(props) {
    const [state, setState] = useReducer(reducer, initialState);
    const [files, setFiles] = useState([]);
    const [progress, setProgress] = useState(0);
    const [load, setLoad] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [questions, setQuestions] = useState([]);

    let endpoint = process.env.REACT_APP_API_ENDPOINT;

    const progressBar = useRef(null);
    const intl = useIntl();
    const {executeRecaptcha} = useGoogleReCaptcha();

    useEffect(() => {
        async function load() {
            let endpoint = process.env.REACT_APP_API_ENDPOINT;
            endpoint += '/questions/get';

            let options = {
                credentials: 'include'
            };
            try {
                const response = await fetch(endpoint, options);
                let json = await response.json();
                onQuestions(json);
            } catch (error) {
                console.log(error);
            }
        }

        load();
    }, []);

    const onQuestions = (json) => {
        setQuestions(json);
    }

    const handleChange = event => {
        setState({error: null});
        setState({[event.target.name]: event.target.value});
    };

    const handleReCaptchaVerify = async () => {
        const token = await executeRecaptcha('mitmachen');
        submit(token);
    };

    const validateForm = () => {
        let isValid = true;
        if (files.length < 1) {
            isValid = false;
            setState({error: 'files'});
            gsap.to('html, body', {scrollTop: document.querySelector('.columns').offsetTop});
        } else if (state.firstname.length < 1) {
            isValid = false;
            setState({error: 'firstname'});
            gsap.to('html, body', {scrollTop: document.querySelector('#firstname').offsetTop});
        } else if (state.lastname.length < 1) {
            isValid = false;
            setState({error: 'lastname'});
            gsap.to('html, body', {scrollTop: document.querySelector('#lastname').offsetTop});
        } else if (!EmailValidator.validate(state.email)) {
            isValid = false;
            setState({error: 'email'});
            gsap.to('html, body', {scrollTop: document.querySelector('#email').offsetTop});
        }

        if(!isValid) {
        }

        return isValid;
    };

    const submit = async (token) => {
        if (!load) {
            if (validateForm()) {
                setLoad(true);

                const formData = new FormData();

                formData.append("file", files[0]);
                formData.append("firstname", state.firstname);
                formData.append("lastname", state.lastname);
                formData.append("email", state.email);
                formData.append("q1", state.q1);
                formData.append("q2", state.q2);
                formData.append("q3", state.q3);
                formData.append("a1", state.a1);
                formData.append("a2", state.a2);
                formData.append("a3", state.a3);
                formData.append("token", token);

                let config = {
                    withCredentials: true,
                    onUploadProgress: (progressEvent) => {
                        let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                        gsap.to(progressBar.current, {width: percentCompleted + "%"});
                        setProgress(percentCompleted);
                    }
                };

                axios
                    .post(endpoint + '/mitmachen', formData, config)
                    .then(response => {
                        setLoad(false);
                        setSubmitted(true);
                    })
            }
        }
    };

    const onDrop = async (acceptedFiles, fileRejections) => {
        setState({error: null});
        setFiles(acceptedFiles.map(file => Object.assign(file, {
            preview: URL.createObjectURL(file)
        })));

        fileRejections.forEach((file) => {
            file.errors.forEach((err) => {
                if (err.code === "file-too-large") {
                    setState({error: "file-too-large"});
                }
            });
        });
    };
    const {getRootProps, getInputProps} = useDropzone({onDrop: onDrop, accept: 'image/png, image/jpeg', maxSize: 1048576*10, multiple: false});

    return (
        <div className="page page-mitmachen">
            {!submitted &&
            <div className="container">
                <h1>
                    {intl.formatMessage(messages.mitmachen.h1)}
                </h1>
                <form>
                    <div className="columns">
                        <div className="column-left">
                            <div {...getRootProps({className: 'dropzone'})}>
                                <input {...getInputProps()}/>
                                {files.length < 1
                                    ? <>
                                        <p>{intl.formatMessage(messages.mitmachen.dropzone)}</p>
                                        {state.error === 'files' &&
                                            <p className="warning">{intl.formatMessage(messages.mitmachen.image)}</p>
                                        }
                                        {state.error === 'file-too-large' &&
                                            <p className="warning">{intl.formatMessage(messages.mitmachen.imageTooLarge)}</p>
                                        }
                                    </>
                                    : <>
                                        <img
                                            src={files[0].preview}
                                        />
                                    </>
                                }
                            </div>
                        </div>
                        <div className="column-right">
                            <input type="text" name="firstname" id="firstname" placeholder={intl.formatMessage(messages.mitmachen.firstname) + "*"} onChange={handleChange}/>
                            {state.error === 'firstname' &&
                                <p className="warning">{intl.formatMessage(messages.mitmachen.firstnameError)}</p>
                            }
                            <input type="text" name="lastname" id="lastname" placeholder={intl.formatMessage(messages.mitmachen.lastname) + "*"} onChange={handleChange}/>
                            {state.error === 'lastname' &&
                                <p className="warning">{intl.formatMessage(messages.mitmachen.lastnameError)}</p>
                            }
                            <input type="text" name="email" id="email" placeholder={intl.formatMessage(messages.mitmachen.email) + "*"} onChange={handleChange}/>
                            {state.error === 'email' &&
                                <p className="warning">{intl.formatMessage(messages.mitmachen.emailError)}</p>
                            }
                            <div className='asterisk'>*{intl.formatMessage(messages.mitmachen.notVisibleForPublic)}</div>
                        </div>
                    </div>
                    {questions.length > 0 &&
                        <>
                            <div className="questions">
                                <div className="question">
                                    <div className="question-label">{intl.formatMessage(messages.mitmachen.question)} 1</div>
                                    <NativeSelect
                                        name="q1"
                                        defaultValue={0}
                                        disableUnderline
                                        IconComponent={SelectIcon}
                                        onChange={handleChange}
                                    >
                                        <option value="0" disabled>{intl.formatMessage(messages.mitmachen.choose)}</option>
                                        {questions.map((option, index) => {
                                            if (option.id !== parseInt(state.q2) && option.id !== parseInt(state.q3)) {
                                                return <option key={index} value={option.id}>{option[intl.locale]}</option>
                                            }
                                        })}
                                    </NativeSelect>
                                    {state.q1 &&
                                        <div className="answer">
                                            <div className="answer-label">{intl.formatMessage(messages.mitmachen.answer)}</div>
                                            <div className="buttons">
                                                <div className={"button" + (state.a1 === 'yes' ? " active" : "")} onClick={() => setState({a1: 'yes'})}>
                                                    {intl.formatMessage(messages.test.yes)}
                                                </div>
                                                <div className={"button" + (state.a1 === 'no' ? " active" : "")} onClick={() => setState({a1: 'no'})}>
                                                    {intl.formatMessage(messages.test.no)}
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </div>
                                {state.a1 &&
                                    <div className="question">
                                        <div className="question-label">{intl.formatMessage(messages.mitmachen.question)} 2</div>
                                        <NativeSelect
                                            name="q2"
                                            defaultValue={0}
                                            disableUnderline
                                            IconComponent={SelectIcon}
                                            onChange={handleChange}
                                        >
                                            <option value="0" disabled>{intl.formatMessage(messages.mitmachen.choose)}</option>
                                            {questions.map((option, index) => {
                                                if (option.id !== parseInt(state.q1) && option.id !== parseInt(state.q3)) {
                                                    return <option key={index} value={option.id}>{option[intl.locale]}</option>
                                                }
                                            })}
                                        </NativeSelect>
                                        {state.q2 &&
                                            <div className="answer">
                                                <div className="answer-label">{intl.formatMessage(messages.mitmachen.answer)}</div>
                                                <div className="buttons">
                                                    <div className={"button" + (state.a2 === 'yes' ? " active" : "")} onClick={() => setState({a2: 'yes'})}>
                                                        {intl.formatMessage(messages.test.yes)}
                                                    </div>
                                                    <div className={"button" + (state.a2 === 'no' ? " active" : "")} onClick={() => setState({a2: 'no'})}>
                                                        {intl.formatMessage(messages.test.no)}
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                }
                                {state.a2 &&
                                    <div className="question">
                                        <div className="question-label">{intl.formatMessage(messages.mitmachen.question)} 3</div>
                                        <NativeSelect
                                            name="q3"
                                            defaultValue={0}
                                            disableUnderline
                                            IconComponent={SelectIcon}
                                            onChange={handleChange}
                                        >
                                            <option value="0" disabled>{intl.formatMessage(messages.mitmachen.choose)}</option>
                                            {questions.map((option, index) => {
                                                if (option.id !== parseInt(state.q1) && option.id !== parseInt(state.q2)) {
                                                    return <option key={index} value={option.id}>{option[intl.locale]}</option>
                                                }
                                            })}
                                        </NativeSelect>
                                        {state.q3 &&
                                            <div className="answer">
                                                <div className="answer-label">{intl.formatMessage(messages.mitmachen.answer)}</div>
                                                <div className="buttons">
                                                    <div className={"button" + (state.a3 === 'yes' ? " active" : "")} onClick={() => setState({a3: 'yes'})}>
                                                        {intl.formatMessage(messages.test.yes)}
                                                    </div>
                                                    <div className={"button" + (state.a3 === 'no' ? " active" : "")} onClick={() => setState({a3: 'no'})}>
                                                        {intl.formatMessage(messages.test.no)}
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                }
                            </div>
                            {state.a3 &&
                                <div className="ds" onClick={() => state.ds ? setState({ds: false}) : setState({ds: true})}>
                                    <div className="checkbox">
                                        {state.ds &&
                                            <div className="inner"></div>
                                        }
                                    </div>
                                    <div>{intl.formatMessage(messages.mitmachen.privacy, {
                                        a: (...chunks) => <a href="/datenschutz" target="_blank">{chunks}</a>,
                                    })}</div>
                                </div>
                            }
                            {state.ds &&
                                <>
                                    <div className="recaptcha-label">
                                        {intl.formatMessage(messages.mitmachen.recaptcha, {
                                            a1: (...chunks) => <a href="https://policies.google.com/privacy" target="_blank">{chunks}</a>,
                                            a2: (...chunks) => <a href="https://policies.google.com/terms" target="_blank">{chunks}</a>,
                                        })}
                                    </div>
                                    <div className="submit" onClick={handleReCaptchaVerify}>{intl.formatMessage(messages.mitmachen.send)}</div>
                                    {load &&
                                        <div className='progress-bar-container'>
                                            <div className='progress-bar'>
                                                <div className='progress-bar-inner' ref={progressBar}></div>
                                            </div>
                                            <div className='progress-bar-text'>{progress} %</div>
                                        </div>
                                    }
                                </>
                            }
                        </>
                    }
                </form>
            </div>
            }
            {submitted &&
                <div className="container">
                    <h1>
                        {intl.formatMessage(messages.test.h1)}
                    </h1>
                    <p>
                        {intl.formatMessage(messages.mitmachen.p_1)}
                    </p>
                    <p>
                        {intl.formatMessage(messages.mitmachen.p_2, {
                            email: <span className="highlight">{state.email}</span>,
                        })}
                    </p>

                    <Link to="/bias-o-mat/test" className="test">{intl.formatMessage(messages.mitmachen.test)}</Link>
                </div>
            }
        </div>
    )
}

export default Mitmachen;