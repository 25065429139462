import React, {useEffect} from "react";
import {gsap, Expo} from "gsap";
import image01 from "img/galerie/foto1.jpg";
import image02 from "img/galerie/foto2.jpg";
import image03 from "img/galerie/foto3.jpg";
import image04 from "img/galerie/foto4.jpg";
import image05 from "img/galerie/foto5.jpg";
import image06 from "img/galerie/foto_6.jpg";
import image07 from "img/galerie/foto_7.jpg";
import image08 from "img/galerie/foto_8.jpg";
import image09 from "img/galerie/foto_9.jpg";
import image10 from "img/galerie/foto_10.jpg";

import imageNeu01 from "img/galerie/_BB18699.jpg";
import imageNeu02 from "img/galerie/_BB19524.jpg";
import imageNeu03 from "img/galerie/_BB19528.jpg";
import imageNeu04 from "img/galerie/_BB19579.jpg";
import imageNeu05 from "img/galerie/_BBF7774.jpg";
import imageNeu06 from "img/galerie/_BBF7788.jpg";
import imageNeu07 from "img/galerie/_BBF7794.jpg";
import imageNeu08 from "img/galerie/_BBF7863.jpg";
import imageNeu09 from "img/galerie/_BBF7891.jpg";

function Gallery(props) {
    const gallery = {
        init: function () {
            gallery.dur = 1;
            gallery.ease = Expo.easeOut;

            gallery.numItems = document.querySelectorAll('.gallery-item').length;
            gallery.x = 0;
            gallery.ofs = 0;
            gallery.loopOfs = 0;

            //gallery.hasAutoPlay = true;

            var galleryItems = document.querySelectorAll('.gallery-item');
            for (let i = 0; i < galleryItems.length; i++) {
                galleryItems[i].classList.remove('current');
            }

            document.querySelectorAll('.gallery-item')[0].classList.add('current');

            var galleryBullets = document.querySelector('.gallery-bullets');
            galleryBullets.innerHTML = '';

            for (let i = 0; i < gallery.numItems; i++) {
                var bullet = document.createElement('div');
                bullet.classList.add('gallery-bullet');
                galleryBullets.appendChild(bullet);
            }
            document.querySelectorAll('.gallery-bullet')[0].classList.add('current');

            document.querySelector('.gallery-next').onclick = gallery.next;
            document.querySelector('.gallery-prev').onclick = gallery.prev;
            document.querySelectorAll('.gallery-bullet').onclick = gallery.bullet;
            document.querySelector('.gallery').onmousedown = gallery.swipe;
            document.querySelector('.gallery').ontouchstart = gallery.swipe;

            gallery.items = document.querySelectorAll('.gallery-items')[0];

            gallery.resize();
        },
        resize: function () {
            gallery.itemWidth = document.querySelector('.gallery-item').offsetWidth;
            if (gallery.items._gsTransform) {
                gallery.items._gsTransform.x = gallery.items._gsTransform.x / gallery.itemWidth * gallery.itemWidth;
            }
            gallery.min = (gallery.numItems - 1) * -gallery.itemWidth - (gallery.loopOfs * gallery.itemWidth);
            gallery.max = (-gallery.loopOfs * gallery.itemWidth);

            gallery.arrange();

            gsap.to('.gallery-items', 0, {x: gallery.ofs * -gallery.itemWidth, onUpdate: gallery.checkBounds});

            if (document.querySelector('.gallery').offsetWidth > document.querySelector('.page').offsetWidth) {
                gsap.to('.gallery-wrapper', 0, {x: (document.querySelector('.page').offsetWidth - document.querySelector('.gallery').offsetWidth) / 2});
            } else {
                gsap.to('.gallery-wrapper', 0, {x: 0});
            }
        },
        arrange: function () {
            var galleryItems = document.querySelectorAll('.gallery-item');
            for (var i = 0; i < gallery.numItems; i++) {
                gsap.to(galleryItems[i], 0, {left: i * gallery.itemWidth - (gallery.loopOfs * -gallery.itemWidth)});
            }
        },
        next: function (event) {
            gallery.ofs++;
            gsap.to('.gallery-items', gallery.dur, {
                x: gallery.ofs * -gallery.itemWidth,
                onUpdate: gallery.checkBounds,
                ease: gallery.ease
            });

            if (event) {
                gallery.hasAutoPlay = false;
            }
        },
        prev: function (event) {
            gallery.ofs--;
            gsap.to('.gallery-items', gallery.dur, {
                x: gallery.ofs * -gallery.itemWidth,
                onUpdate: gallery.checkBounds,
                ease: gallery.ease
            });

            if (event) {
                gallery.hasAutoPlay = false;
            }
        },
        checkBounds: function () {
            if (gsap.getProperty(gallery.items, 'x') < gallery.min) {
                gallery.loopOfs++;
                var galleryItems = document.querySelectorAll('.gallery-item');
                var firstChild = galleryItems[0];
                gallery.items.appendChild(firstChild);
                gallery.arrange();
                gallery.min -= gallery.itemWidth;
                gallery.max -= gallery.itemWidth;
            }
            if (gsap.getProperty(gallery.items, 'x') > gallery.max) {
                gallery.loopOfs--;
                var galleryItems = document.querySelectorAll('.gallery-item');
                var lastChild = galleryItems[galleryItems.length - 1];
                gallery.items.insertBefore(lastChild, gallery.items.firstChild);
                gallery.arrange();
                gallery.max += gallery.itemWidth;
                gallery.min += gallery.itemWidth;
            }
            var galleryItems = document.querySelectorAll('.gallery-item');
            var galleryBullets = document.querySelectorAll('.gallery-bullet');
            for (var i = 0; i < galleryItems.length; i++) {
                galleryItems[i].classList.remove('current');
                galleryBullets[i].classList.remove('current');
            }

            var ofs = gallery.ofs - gallery.loopOfs;
            if (ofs < 0) {
                ofs = gallery.numItems + ofs;
            }
            if (ofs > gallery.numItems - 1) {
                ofs = gallery.numItems - 1;
            }
            document.querySelectorAll('.gallery-item')[ofs].classList.add('current');
            document.querySelectorAll('.gallery-bullet')[ofs].classList.add('current');
        },
        swipe: function (event) {
            gallery.currentGallery = this;

            if (event.type.toLowerCase() === 'mousedown') {
                gallery.swipeX = event.pageX;
            } else {
                gallery.swipeX = event.changedTouches[0].pageX;
            }
            window.onmouseup = gallery.swipeEnd;
            window.ontouchend = gallery.swipeEnd;
        },
        swipeEnd: function (event) {
            window.onmouseup = null;
            window.ontouchend = null;

            var x;
            if (event.type.toLowerCase() === 'mouseup') {
                x = event.pageX;
            } else {
                x = event.changedTouches[0].pageX;
            }
            var dist = gallery.swipeX - x;
            if (Math.abs(dist) > 50) {
                if (dist > 0) {
                    gallery.next(null);
                } else {
                    gallery.prev(null);
                }
            }
        }
    };

    useEffect(() => {
        gallery.init();

        window.onresize = gallery.resize;
        gallery.resize();

        return () => {
            window.onresize = null;
        }
    }, []);

    return (
        <div className="gallery-wrapper">
            <div className="gallery">
                <div className="gallery-items-wrapper">
                    <div className="gallery-items">
                        <div className="gallery-item current">
                            <img alt="" src={imageNeu01}/>
                        </div>
                        <div className="gallery-item">
                            <img alt="" src={imageNeu02}/>
                        </div>
                        <div className="gallery-item">
                            <img alt="" src={imageNeu03}/>
                        </div>
                        <div className="gallery-item">
                            <img alt="" src={imageNeu04}/>
                        </div>
                        <div className="gallery-item">
                            <img alt="" src={imageNeu05}/>
                        </div>
                        <div className="gallery-item">
                            <img alt="" src={imageNeu06}/>
                        </div>
                        <div className="gallery-item">
                            <img alt="" src={imageNeu07}/>
                        </div>
                        <div className="gallery-item">
                            <img alt="" src={imageNeu08}/>
                        </div>
                        <div className="gallery-item">
                            <img alt="" src={imageNeu09}/>
                        </div>
                        <div className="gallery-item">
                            <img alt="" src={image01}/>
                        </div>
                        <div className="gallery-item">
                            <img alt="" src={image02}/>
                        </div>
                        <div className="gallery-item">
                            <img alt="" src={image03}/>
                        </div>
                        <div className="gallery-item">
                            <img alt="" src={image04}/>
                        </div>
                        <div className="gallery-item">
                            <img alt="" src={image05}/>
                        </div>
                        <div className="gallery-item">
                            <img alt="" src={image06}/>
                        </div>
                        <div className="gallery-item">
                            <img alt="" src={image07}/>
                        </div>
                        <div className="gallery-item">
                            <img alt="" src={image08}/>
                        </div>
                        <div className="gallery-item">
                            <img alt="" src={image09}/>
                        </div>
                        <div className="gallery-item">
                            <img alt="" src={image10}/>
                        </div>
                    </div>
                </div>
                <div className="gallery-navi">
                    <div className="gallery-next"></div>
                    <div className="gallery-prev"></div>
                </div>
                <div className="gallery-bullets-wrapper">
                    <div className="gallery-bullets-left"></div>
                    <div className="gallery-bullets"></div>
                    <div className="gallery-bullets-right"></div>
                </div>
            </div>
        </div>
    )
}

export default Gallery;